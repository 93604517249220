import { Link } from "react-router-dom";
import blogOneData from "./BlogOneData";

const BlogOne = () => {
    return (
        <>
            <section className="news-wrapper section-padding pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>BLOG</span>
                                <h2>EN SON HABERLER</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {
                        blogOneData.map((blog) => (
                            <div className="col-xl-4 col-md-6 col-12" key={blog.id}>
                                <div className="single-blog-card style-1">
                                    <div className="blog-featured-img bg-cover bg-center" style={{backgroundImage: `url(${blog.thumb})`}}></div>
                                    <div className="contents">
                                        <div className="post-metabar d-flex justify-content-between align-items-center">
                                          <div className="post-date">
                                                <i className="fal fa-calendar-alt"></i>
                                                <a href={blog.btnLink}>{blog.date}</a>
                                            </div>
                                        </div>
                                        <h3> <a href={blog.btnLink}>{blog.title}</a></h3>
                                        <a href={blog.btnLink} className="read-more-link">{blog.btnText}</a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                   
                </div>
            </div>
        </section>
        </>
    )
}

export default BlogOne;