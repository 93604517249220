const HeroSocials = () => {
    return (
        <>
        <div className="hero-social-elements d-none d-xxl-block">
        <div className="flp-text">
        </div>
        
        <div className="social-link">
            <a href="https://www.facebook.com/profile.php?id=100090930177214"><i className="fab fa-facebook-f"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-linkedin-in"></i></a>
        </div>
    </div>
        </>
    )
}

export default HeroSocials
