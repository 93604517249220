import { useState } from "react";
import { Link } from "react-router-dom";
import mainLogo from '../../assets/img/logo-header.png';
import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";

const HeaderOne = () => {
  const [search, setSearch] = useState(true);
  const [offset, setOffset] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(true);

  const handleSearch = () => {
    setSearch(!search);
  }

  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  }

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  }
  return (
    <>
    <OffsetMenu offset={offset} handleOffset={handleOffset}  />
    <header className="header-wrap header-1">
        <div className="container-fluid d-flex justify-content-between align-items-center">
            <div className="logo">
                <Link to='/'>
                    <img src={mainLogo} alt="logo"/>
                </Link>
            </div>
            <div className="header-right-area d-flex">
                <div className="main-menu d-none d-xl-block">
                    <ul>
                        <li><a href="/">ANASAYFA</a></li>
                        <li><a href="/hakkimizda">HAKKIMIZDA</a></li>
                        <li><a href="/evden-eve-nakliyat">EVDEN EVE NAKLİYAT</a></li>
                        <li><a href="/esya-depolama">EŞYA DEPOLAMA</a></li>
                        <li><a href="/blog">BLOG</a></li>
                        <li><a href="/iletisim">İLETİŞİM</a></li>
                    
                        
                    </ul>
                </div>
                <div className="header-right-elements d-flex align-items-center justify-content-between">
                    {/* <Link to="/iletisim" className="theme-btn d-none d-sm-block">Bize Ulaşın</Link>  */}
                    <a href="tel:+90-532-389-45-95" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">BİZE ULAŞIN</a>
                    <span onClick={handleOffset} className="side-menu-toggle d-none d-xl-block"><i className="fal fa-bars"></i></span>
                    <div className="d-inline-block ms-4 d-xl-none">
                        <div className="mobile-nav-wrap">                    
                            <div id="hamburger" onClick={handleMobileMenu}>
                                <i className="fal fa-bars"></i>
                            </div>
                            <MobileMenu mobileMenu={mobileMenu} handleMobileMenu={handleMobileMenu} />
                        </div>
                        <div className="overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    </>
  )
}

export default HeaderOne;
