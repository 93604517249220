import { isValidElement } from 'react';
import {v4 as uuidv4} from 'uuid';

const faqOneData = [
    {
        id: uuidv4(),
        ques: 'Eşyalar araca yüklenirken paketleme yapılıyor mu ?',
        ans: ' Evet.Eşyalarınız taşınma günü özel ambalaj malzemeleri ile paketlenerek araca yüklenir.',
        index: 'faq1',
    },

    {
        id: uuidv4(),
        ques: 'Mobilyalarım için demontaj ve montaj hizmeti veriyor musunuz ?',
        ans: 'Evet.Taşınma esnasında ihtiyaç duyulan eşyalarınız için yükleme öncesi demontaj ve gideceği yerde de  montaj hizmeti vermekteyiz.',
        index: 'faq2'
    },

    {
        id: uuidv4(),
        ques: 'Araçlarınız kapalı mı ? Eşyalarım yağışlı havalarda ıslanır mı ?',
        ans: 'Araçlarımız kötü hava şartlarından etkilenmeyecek şekilde kapalı kasa olarak hizmet vermektedir.',
        index: 'faq3'
    },

    {
        id: uuidv4(),
        ques: 'Taşınma öncesi Buzdolabını boşaltmalı mıyım ?',
        ans: 'Evet buzdolabınızı taşınma öncesinde boşaltmalısınız.Ayrıca bozulma riski olan ürünleriniz için de öncesinde önlem almanız faydalı olacaktır.',
        index: 'faq4'
    },

    {
        id: uuidv4(),
        ques: 'Asansör ile taşıma hizmeti veriyor musunuz ?',
        ans: 'Evet.İhtiyacınıza göre uygun asansörler ile hizmet vermekteyiz.',
        index: 'faq5'
    },

]

export default faqOneData;