import {v4 as uuidv4} from 'uuid';
import bg1 from '../../assets/img/services/1.jpg';
import bg2 from '../../assets/img/services/2.jpeg';
import bg3 from '../../assets/img/services/3.jpeg';
import bg4 from '../../assets/img/services/4.jpg';
import bg5 from '../../assets/img/services/5.jfif';

const servicesOneData = [
   
    {
        id: uuidv4(),
        bg: bg2,
        icon: 'interior-design',
        title: 'EVDEN EVE NAKLİYAT',
        desc: '',
    },

    {
        id: uuidv4(),
        bg: bg3,
        icon: 'home',
        title: 'ŞEHİRLERARASI NAKLİYAT',
        desc: '',
    },

    {
        id: uuidv4(),
        bg: bg4,
        icon: 'interior-design',
        title: 'ASANSÖRLÜ TAŞIMACILIK',
        desc: '',
    },

    {
        id: uuidv4(),
        bg: bg5,
        icon: 'home',
        title: 'PAKETLEME',
        desc: '',
    },
    
]

export default servicesOneData;