import { Link } from "react-router-dom";
import FooterOneCopyrights from "./FooterOneCopyrights";
import { useState } from "react";
import FooterCta from "./FooterCta";

const FooterOne = () => {
    const [input, setInput] = useState('');
    return (
        <>
            <footer className="footer-1 footer-wrap">
                <div className="footer-widgets-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
                                <div className="single-footer-wid site_info_widget">
                                    <div className="wid-title">
                                        <h3>BİZE ULAŞIN</h3>
                                    </div>
                                    <div className="contact-us">
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-phone"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p>0532 749 79 50</p>
                                                <p>0532 389 45 95</p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-envelope"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p>info@adaslardepolama.com</p>
                                                
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-map-marker-alt"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p>Keklikpınarı Mah. 922.Sok. No:8  <br/>
                                                Dikmen/ANKARA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
                                <div className="single-footer-wid">
                                    <div className="wid-title">
                                        <h3>HİZMETLERİMİZ</h3>
                                    </div>
                                    <ul>
                                         <li><a href="/esya-depolama">Eşya Depolama</a></li>
                                        <li><a href="/evden-eve-nakliyat">Evden Eve Nakliyat</a></li>
                                        <li><a href="/evden-eve-nakliyat">Şehirlerarası Taşımacılık</a></li>
                                        <li><a href="/evden-eve-nakliyat">Ofis Taşıma</a></li>
                                     </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">                        
                                <div className="single-footer-wid newsletter_widget">
                                    <div className="wid-title">
                                        <h3>DEPOLAMA HİZMETİ SUNDUĞUMUZ İLÇELER</h3>
                                    </div>
                                        <ul> 
                                            <li><a href="/esya-depolama">Akyurt Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Altındağ Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Çankaya Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Elmadağ Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Etimesgut Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Kalecik Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Kazan Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Keçiören Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Akyurt Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Sincan Eşya Depolama</a></li>
                                            <li><a href="/esya-depolama">Yenimahalle Eşya Depolama</a></li>
                                         </ul>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>

                <FooterCta/>
                <FooterOneCopyrights/>
            </footer> 
        </>
    )
}

export default FooterOne;