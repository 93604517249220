import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import post1 from '../../assets/img/blog/blog-kapak-1.jpg';
import ModalVideo from 'react-modal-video';


const NewsWrapper = () => {
    const [isOpen, setOpen] = useState(false);

  return (
    <>
        <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="EZ9DrY43wtw"
            onClose={() => setOpen(false)}
        />
        <section className="blog-wrapper news-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="blog-posts">
                            <div className="single-blog-post">
                                <div className="post-featured-thumb bg-cover" style={{backgroundImage:`url(${post1})`}}></div>
                                <div className="post-content">
                                    <div className="post-cat">
                                       
                                    </div>
                                    <h2> <a href="/blog">İLK KEZ TAŞINACAKLAR İÇİN İPUÇLARI</a></h2>
                                    <div className="post-meta">
                                        <span><i className="fal fa-calendar-alt"></i>28 KASIM 2022</span>
                                    </div>
                                    <p>Taşınmak stresli bir iştir.Eşyaları toplamak , herşeyi paketlemek ve tekrar taşınma sonrası yerleştirmek çok yorucu süreçlerdir.Üstelik bir de taşınmak için kısıtlı zamanınız var ise bu durum içinden çıkılmaz bir hal alabilir.</p>
                                    <div className="d-flex justify-content-between align-items-center mt-30">
                                       <div className="post-link">
                                           <a href="/"><i className="fal fa-arrow-right"></i> Daha Fazla Oku</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                           
                           
                           
                        </div>
                        {/* <div className="page-nav-wrap mt-60 text-center">
                            <ul>
                                <li><Link to="#"><i className="fal fa-long-arrow-left"></i></Link></li>
                                <li><Link to="#">01</Link></li>
                                <li><Link to="#">02</Link></li>
                                <li><Link to="#"><i className="fal fa-long-arrow-right"></i></Link></li>
                            </ul>
                        </div>  */}
                    </div>
                </div>
            </div>
        </section>
    </>
   
  )
}

export default NewsWrapper;