import React from 'react'
import icon1 from '../assets/img/icons/t1.png';
import icon2 from '../assets/img/icons/1+1.png';


const Timeline = () => {
  return (
    <section className="company-timeline-wrapper section-padding">
        <div className="container">
            <div className="row mtm-40">
                <div className="col-lg-4  col-md-6 offset-lg-1 pe-lg-0">
                    <div className="timeline-content text-lg-end">
                        <div className="single-timeline-box">
                            <span>1990 - 1997</span>
                            <h6>KURULUŞ</h6>
                            <p>Firmamız Ankara'da kurularak ilk faaliyet alanı olan <strong>Evden Eve</strong> sektöründe hizmet vermeye başlamıştır.</p>
                        </div>
                        <div className="single-timeline-box">
                            <span>1997 - 2005</span>
                            <h6>OFİS TAŞIMACILIĞINDA İLK HİZMET</h6>
                            <p><strong>Ofis Taşımacılığı</strong> alanında faaliyet göstermeye başladık.Evrak ve Arşiv taşıma gibi daha planlı süreçleri en başarılı şekilde yürüterek faaliyet alanımızı büyüttük. </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 d-none mt-40 d-lg-block">
                    <div className="time-line-icon">
                        <div className="single-icon">
                            <img src={icon1} alt=""/>
                        </div>
                        <div className="single-icon">
                            <img src={icon2} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 ps-lg-0">
                    <div className="timeline-content">
                        <div className="single-timeline-box">
                            <span>2005 - 2014</span>
                            <h6>ASANSÖRLÜ TAŞIMA BAŞLADI</h6>
                            <p>Özellikle Avrupada yaygın olarak kullanılan <strong>asansörlü taşımacılığı</strong> Türkiye'de ilk uygulayan firmalardan biri olduk.Müşteri portföyümüzü büyütmeye devam ettik. </p>
                        </div>
                        <div className="single-timeline-box">
                            <span>2014- BUGÜNE</span>
                            <h6>EŞYA DEPOLAMA</h6>
                            <p>Çağın gereksinimlerinin değişmesi ile ortaya çıkan <strong>Eşya Depolama</strong> sorununa çözüm olarak bu alanda da hizmet vermeye başladık.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-lg-5 mt-3">
                <div className="col-md-6 col-12 col-lg-4">
                    <div className="single-icon-border-box">
                        <div className="icon">
                            <i className="flaticon-trust"></i>
                        </div>
                        <div className="content">
                            <h6><span>GÜVENİLİR</span>HİZMET</h6>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                    <div className="single-icon-border-box">
                        <div className="icon">
                            <i className="flaticon-cashier-machine"></i>
                        </div>
                        <div className="content">
                            <h6><span>UYGUN</span> ÇÖZÜMLER</h6>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                    <div className="single-icon-border-box">
                        <div className="icon">
                            <i className="flaticon-goal"></i>
                        </div>
                        <div className="content">
                            <h6><span>MÜŞTERİ </span>MEMNUNİYETİ</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Timeline