import {v4 as uuidv4} from 'uuid';
import thumb1 from '../../assets/img/blog/blog-kapak-1.jpg';
import thumb2 from '../../assets/img/blog/blog-kapak-2.jpg';
import thumb3 from '../../assets/img/blog/blog-kapak-3.jpeg';
import authorImg from '../../assets/img/blog/author2.jpg';
import authorImg1 from '../../assets/img/blog/author3.jpg';

const blogOneData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        title: 'İLK KEZ TAŞINACAKLAR İÇİN İPUÇLARI',
        btnText: 'Daha Fazla Oku',
        btnLink: '/blog',
        date: '28 MAY 2022'
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        title: 'EVDEN EVE NAKLİYAT FİRMASINI SEÇMEK İÇİN İPUÇLARI',
        btnText: 'Daha Fazla Oku',
        btnLink: '/blog',
        date: '12 AĞU 2022'
    },

    {
        id: uuidv4(),
        thumb: thumb3,
        title: 'NAKLİYAT FİRMASI GELMEDEN ÖNCE YAPMANIZ GEREKENLER',
        btnText: 'Daha Fazla Oku',
        btnLink: '/blog',
        date: '20 KAS 2022'
    },
]

export default blogOneData;