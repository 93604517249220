import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/home2/1.jpg';
import img2 from '../../assets/img/home2/2.jpeg';
import img3 from '../../assets/img/home2/3.jpeg';


const servicesTwoData = [
    {
        id: uuidv4(),
        img: img1,
        icon: 'construction-site',
        title: 'EŞYA DEPOLAMA',
        desc: 'Her türlü ihtiyacınıza uygun Eşya Depolama çözümlerimiz ile hizmetinizdeyiz.',
        url: '/esya-depolama'
    },

    {
        id: uuidv4(),
        img: img2,
        icon: 'interior-design',
        title: 'EVDEN EVE NAKLİYAT',
        desc: 'Evden Eve Nakliyat alanında uzman ekiplerimiz ile güvenle ve kolayca taşının.',
        url: '/evden-eve-nakliyat'
    },

    {
        id: uuidv4(),
        img: img3,
        icon: 'interior-design',
        title: 'ŞEHİRLERARASI TAŞIMACILIK',
        desc: "Şehirdışı Taşımacılık alanında yaygın hizmet ağımız ile hizmetinizdeyiz.",
        url: '/evden-eve-nakliyat'
    },
    
]

export default servicesTwoData;