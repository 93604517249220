import coreBg from '../../assets/img/home1/neden-biz.jpg';

const FeaturesOne = () => {
    return (
        <section className="core-features-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-6 pe-lg-0 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <h2>NEDEN BİZ ?</h2>
                            </div>
                        </div>
                        <div className="single-icon-circle-item">
                            <div className="icon">
                                <i className="flaticon-cpu"></i>
                            </div>
                            <div className="contents">
                                <h3>SEKTÖR TECRÜBESİ</h3>
                                <p>Uzun yıllardır hizmet verdiğmiz <strong>Evden Eve Nakliyat</strong> ve <strong>Eşya Depolama</strong> alanında edindiğimiz tecrübe ile her zaman hizmetinizdeyiz</p>
                            </div>
                        </div>
                        <div className="single-icon-circle-item">
                            <div className="icon">
                                <i className="flaticon-secure-shield"></i>
                            </div>
                            <div className="contents">
                                <h3>GÜVENLİ HİZMET</h3>
                                <p>Tüm hizmetlerimizde  öncelikle güven ve müşteri memnuniyeti yaklaşımını benimsemekteyiz.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div className="core-bg d-none d-xl-block bg-cover" style={{backgroundImage: `url(${coreBg})`}}></div>
        </section>
    )
}

export default FeaturesOne;