import {v4 as uuidv4} from 'uuid';

const workProcessData = [
    {
        id: uuidv4(),
        icon: 'writing',
        title: 'ÜCRETSİZ EXPERTİZ',
        desc: 'Dilediğiniz her an iletişime geçerek  ücretsiz ekspertiz hizmetimizden faydalanabilirsiniz.',
    },

    {
        id: uuidv4(),
        icon: 'secure-shield',
        title: 'SİGORTALI DEPOLAMA',
        desc: 'Eşyalarınızı sigortalı depolarımızda gönül rahatlığı ile depolayabilirsiniz.',
    },    

    {
        id: uuidv4(),
        icon: 'interior-design',
        title: 'MONTAJ HİZMETİ',
        desc: 'Taşınma esnasında ücretsiz montaj hizmetimizden faydalanabilirsiniz.',
    },    
]

export default workProcessData;