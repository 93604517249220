import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from '../../assets/img/home1/slayt-1.jpeg';
import slide2 from '../../assets/img/home1/slayt-2.jpeg';

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";


const HeroOne = () => {
    return (
        <section className="hero-wrapper hero-1">
        <Swiper navigation={true} modules={[Navigation]} loop>
            <SwiperSlide className=" bg-cover" style={{backgroundImage: `url(${slide1})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                            <div className="hero-contents pe-lg-3">
                                <h1 className="fs-lg wow fadeInLeft animated text-dark" data-wow-duration="1.3s">DEPOLAMA HİZMETLERİ</h1>
                                <p className="pe-lg-5 wow fadeInLeft animated text-dark" data-wow-duration="1.3s" data-wow-delay=".4s">Size en uygun çözümleri için</p>
                                <a href="/iletisim" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">FİYAT AL</a>
                                <a href="tel:+90-532-749-79-50" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">HEMEN ARAYIN</a>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className=" bg-cover" style={{backgroundImage: `url(${slide2})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                            <div className="hero-contents pe-lg-3">
                                <h1 className="fs-lg wow fadeInLeft animated text-dark" data-wow-duration="1.3s">EVDEN EVE NAKLİYAT</h1>
                                <p className="pe-lg-5 wow fadeInLeft animated text-dark" data-wow-duration="1.3s" data-wow-delay=".4s">Hizmetlerimiz için ulaşın...</p>
                                <a href="contact.html" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">Daha Fazla</a>
                                <a href="tel:+90-532-389-45-95" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">HEMEN ARAYIN</a>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
    )
}

export default HeroOne;
