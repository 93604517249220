import React from 'react'
import bannerBg from '../../assets/img/evden-eve.png';


const BestFeatures = () => {
  return (
    <section className="best-featured-wrapper section-padding pb-5">
        <div className="container">
            <div className="row mtm-40 align-items-center">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="single-best-featured right">
                <div className="icon">
                    <i className="flaticon-flow-chart" />
                </div>
                <div className="content-text">
                    <h5>YENİLİKÇİ</h5>
                    <p>Hizmet verdiğimiz alanda gelişmeleri yakından takip ederek kendimizi yeniliyoruz...</p>
                </div>
                </div>
                <div className="single-best-featured right">
                <div className="icon">
                    <i className="flaticon-writing" />
                </div>
                <div className="content-text">
                    <h5>ÜCRETSİZ EKSPERTİZ</h5>
                    <p>Hizmet almak istediğiniz her konuda ekspertizlerimiz yanınızda... </p>
                </div>
                </div>
                <div className="single-best-featured right">
                <div className="icon">
                    <i className="flaticon-interior-design" />
                </div>
                <div className="content-text">
                    <h5>PAKETLEME HİZMETİ</h5>
                    <p>Dilerseniz taşıma öncesi paketleme hizmetimizden de faydalanabilirsiniz...</p>
                </div>
                </div>
            </div>
            <div className="col-lg-4 p-md-0 mt-5 mb-5 mt-md-0 mb-md-0 col-md-6 col-12">
                <div className="center-img text-center mbm-50">
                <img src={bannerBg} alt="" />
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
                <div className="single-best-featured">
                <div className="icon">
                    <i className="flaticon-secure-shield" />
                </div>
                <div className="content-text">
                    <h5>GÜVENLİ TAŞIMA</h5>
                    <p>Eşyalarınız her daim güvende...</p>
                </div>
                </div>
                <div className="single-best-featured">
                <div className="icon">
                    <i className="flaticon-team" />
                </div>
                <div className="content-text">
                    <h5>TECRÜBELİ EKİP</h5>
                    <p>Alanında uzman ekiplerimiz ile güvenle ve özenle taşının...</p>
                </div>
                </div>
                <div className="single-best-featured">
                <div className="icon">
                    <i className="flaticon-profits" />
                </div>
                <div className="content-text">
                    <h5>UYGUN FİYATLAR</h5>
                    <p>Bütçenize uygun fiyatlarımızla hizmetinizdeyiz...</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>

  )
}

export default BestFeatures