import React from 'react'


const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu" id="mobile-menu">
            
                         <li><a href="/">ANASAYFA</a></li>
                        <li><a href="/hakkimizda">HAKKIMIZDA</a></li>
                         <li><a href="/evden-eve-nakliyat">EVDEN EVE NAKLİYAT</a></li>
                        <li><a href="/esya-depolama">EŞYA DEPOLAMA</a></li>
                        <li><a href="/blog">BLOG</a></li>
                        <li><a href="/iletisim">İLETİŞİM</a></li>
                        
              </ul>
        </nav>

        <div className="action-bar">
            <a href="mailto:info@adaslardepolama.com"><i className="fal fa-envelope"></i>İnfo@adaslardepolama.com</a>
            <a href="tel:+90-532-749-79-50"><i className="fal fa-phone"></i>BİZE ULAŞIN</a>
            <a href="/iletisim" className="d-btn theme-btn">TEKLİF AL</a>
        </div>
    </div>    
     
  )
}

export default MobileMenu