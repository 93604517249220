import { Link } from 'react-router-dom';

const d = new Date();
let currentYear = d.getFullYear();

const FooterOneCopyrights = () => {
    return (
        <div className='footer-bottom'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 col-12 text-center text-md-start">
                        <div className="copyright-info">
                            <p>&copy; {currentYear}  <a href="/">Adaslar Depolama</a>. Tüm Hakları Saklıdır</p>                            
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="footer-menu mt-3 mt-md-0 text-center text-md-end">
                            <ul>
                                <li> <a href="/hakkimizda">Hakkımızda</a></li>
                                <li> <a href="/iletisim">İletişim</a></li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default FooterOneCopyrights;