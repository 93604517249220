import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

const ContactPageContents = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        alert("Talebiniz İletildi.Teşekkürler")
       
    
        emailjs.sendForm('service_2t44xef', 'template_drcqoq9', form.current, 'dvCaF35gaYvPM3Mqb')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          setName("");
          setMail("");
          setPhone("");
          setSubject("");
          setMessage("");
      };
      const [name,setName] = useState();
      const [mail,setMail] = useState();
      const [phone,setPhone] = useState();
      const [subject,setSubject] = useState();
      const [message,setMessage] = useState();
    

    return (
        <div className="contact-us-wrapper section-padding">
            <div className="container">
                <p className="display-6">Sizi arayalım...</p>
                <div className="row eq-height">
                <div className="col-lg-8 col-12">
                    <div className="contact-form"> 
                    <h2>TALEP FORMU</h2>                                                       
                    
                    <form ref={form} className="row" id="contact-form" onSubmit={sendEmail}>
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="text" name="user_name" placeholder="Ad" value={name} onChange={(e) => setName(e.target.value)} />                                         
                        </div>
                    </div>                            
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="email" name="user_email" placeholder="Email" value={mail} />                                         
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="text" name="user_phone" placeholder="Telefon" value={phone} />                                         
                        </div>
                    </div>                                      
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="text" name="subject" placeholder="Talep Konusu" value={subject} />                                         
                        </div>
                    </div>                                      
                    <div className="col-md-12 col-12">
                        <div className="single-personal-info">
                        <textarea name="message" placeholder="Mesajınız" defaultValue={""} value={message} />                                        
                        </div>
                    </div>                                      
                    <div className="col-md-12 col-12">
                        <input className="submit-btn" type="submit" value="Gönder" />
                    </div>                                      
                    </form>
                    <span className="form-message" />
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="contact-us-sidebar mt-5 mt-lg-0">
                        {/* <div className="contact-info">
                            <h2>CONTACT INFO</h2>
                            <div className="single-info">
                                <div className="icon">
                                    <i className="flaticon-email" />
                                </div>
                                <div className="text">
                                    <span>Email Us</span>
                                    <h5>info@example.com</h5>
                                </div>
                            </div>
                            <div className="single-info">
                                <div className="icon">
                                    <i className="flaticon-phone-call-1" />
                                </div>
                                <div className="text">
                                    <span>Call Us</span>
                                    <h5>+673 853 605 985</h5>
                                </div>
                            </div>
                            <div className="single-info">
                                <div className="icon">
                                    <i className="flaticon-pin" />
                                </div>
                                <div className="text">
                                    <span>Location</span>
                                    <h5>4821 Mainer St NY, USA</h5>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="live-chat">
                            <h2>Live Chat</h2>
                            <p>You can contact us on chat and explain 
                            your problems</p>
                            <Link to="/about"><i className="flaticon-chatting" /> Live Chat to Executive</Link>
                        </div> */}
                    </div>
                </div>
                </div>
            </div>
        </div>

    )
}

export default ContactPageContents;
