import React from 'react'
import FooterOne from '../Components/Footer/FooterOne';
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/contact-banner.png';
import PageBanner from '../Components/PageBanner';
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import ServicesTwo from '../Components/Services/ServicesTwo';




const ContactPage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='BİZE ULAŞIN' bannerBg={bannerBg} currentPage='İLETİŞİM' />
        <ContactPageContents/>
        {/* <BrandCarouselThree/> */}
        <ServicesTwo/>
        <FooterOne/>

    </>
  )
}

export default ContactPage;
