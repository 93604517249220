import React from 'react'
import FooterOne from '../Components/Footer/FooterOne'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/contact-banner.png';
import PageBanner from '../Components/PageBanner';
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import BestFeatures from "../Components/Features/BestFeatures";
import Pricing from "../Components/Price/Pricing";
import FaqOne from "../Components/Faq/FaqOne";

const ServicePage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='EVDEN EVE NAKLİYAT' bannerBg={bannerBg} currentPage='EVDEN EVE NAKLİYAT' />
        <ServicesOne/>
        <ServicesCta/>
        <BestFeatures/>
        {/* <Pricing/> */}
        <FaqOne/>
        <FooterOne/>
    </>
  )
}

export default ServicePage