import React from 'react'
import icon1 from '../../assets/img/icons/1+1.png';
import icon2 from '../../assets/img/icons/2+1.png';
import icon3 from '../../assets/img/icons/3+1.png';
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div>
    <section className="pricing-wrapper section-padding pricing-bg">
        <div className="container">
        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
            <div className="block-contents text-center">
                <div className="section-title">
                <span>DEPO FİYAT TABLOSU</span>
                <h2>İHTİYACINIZA UYGUN EŞYA DEPOLAMA ÇÖZÜMLERİMİZ</h2>
                </div>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
            <div className="single-pricing-plan">
                <div className="package-head">
                <div className="icon">
                    <img src={icon1} alt="" />
                </div>
                <div className="package-title">
                    <h6>KÜÇÜK DEPO</h6>
                    <h2>1000 ₺</h2>
                </div>
                </div>
                <div className="package-features">
                <h6>20 m³</h6>
                <span>1+0 Evler</span>
                <span>Parça Eşyalar</span>
                </div>
                <div className="package-btn">
                <a href="tel:+90-532-389-45-95">TALEP ET</a>
                </div>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <div className="single-pricing-plan active">
                <div className="package-head">
                <div className="icon">
                    <img src={icon2} alt="" />
                </div>
                <div className="package-title">
                    <h6>ORTA DEPO</h6>
                    <h2>2000 ₺</h2>
                </div>
                </div>
                <div className="package-features">
                <h6>40 m³</h6>
                <span>1+1 Evler</span>
                <span>2+1 Evler</span>
               
                </div>
                <div className="package-btn">
                <a href="tel:+90-532-389-45-95">TALEP ET</a>
                </div>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <div className="single-pricing-plan">
                <div className="package-head">
                <div className="icon">
                    <img src={icon3} alt="" />
                </div>
                <div className="package-title">
                    <h6>BÜYÜK DEPO</h6>
                    <h2>2500 ₺</h2>
                </div>
                </div>
                <div className="package-features">
                <h6>60 m³</h6>
                <span>3+1 Evler</span>
                <span>4+1 Evler</span>
                
                </div>
                <div className="package-btn">
                <a href="tel:+90-532-389-45-95">TALEP ET</a>
                </div>
            </div>
            </div>
          </div>
        </div>
    </section>  


    <section className="pricing-wrapper section-padding pricing-bg">
     <div className="row">
            <div className="block-contents text-left mx-4">
                <div className="section-title">
              <h2>EŞYA DEPOLAMA</h2>
               <span> EŞYA DEPOLAMA HAKKINDA MERAK EDİLENLER</span>
                <p >Günümüzde özellikle apartman yaşamında evlerde kullanılan eşya sayısı arttıkça,yaşam alanlarımızda sıkışıklıklar ve yer sorunları ortaya çıkmaya başladı.Hatta bu gibi sorunlar strese ve nihayetinde psikolojik sorunlara bile neden olabilmektedir.. Bu gibi durumlarda <strong>Eşya Depolama</strong> hizmeti almak hayat kurtarıcı olabilir. 
                </p>
                <h4>Eşya Depoalama Nedir?</h4>
                <p>
                <strong>Eşya Depolama</strong> ihtiyacı hayatımızın çok farklı yerlerinde karşımıza çıkabilecek bir durumdur.Mesela işimiz gereği şehir değiştirmemiz gerekebilir ve eşyaları yanmızda götüremeyeceğimiz bir durum olabilir.Veya başka bir şehirde sadece yazları kullandığımız bir evimizi kentsel dönüşüm nedeni ile yıkılabilir ve eşyalarımızı geçici olarak koyabileceğimiz bir <strong>Eşya Deposu</strong> ihtiyacımız ortaya çıkabilir.Bu gibi durumlarda,özellikle güçlü bir altyapıya sahip <strong>Depolama firmaları</strong> çözüm için uygun bir seçenek olabilir.Hatta bu firmalar <strong>Evden Eve Nakliyat</strong> hizmetini de sağlayarak,Eşyalarınızın <strong>Depo</strong>ya götürülme sürecini başından sonuna kadar yürüterek, bu zorlu sürecin rahatlıkla üstesinden gelmenizi sağlayabilir.Yine hizmet alacağınız <strong>Eşya Depolama</strong> firmaları güvenli depoları ile eşyalarınızın <strong>Depolama</strong> süresi boyunca hasar görmemesini sağlar.
                </p>
                <h4>Ne zaman İhtiyaç Duyarım?</h4>
                <p>
                    <strong>Eşya Depolama</strong> ihtiyacı hayatımızın çok farklı dönemlerinde ihtiyaç duyabileceğimiz bir hizmettir.Örneğin,mevcut işimiz gereği bir süreliğine yurtdışına gitmemiz gerebilir ve bu süre zarfında eşyalarımızı güvenli ve ekonomik bir yerde <strong>depolama</strong>ya ihtiyacımız olabilir.Yine evimizde zaman içerisinde ihtiyacı ortadan kalktığı veya yenisi alındığı için kullanılmayan <strong>eşyalar</strong>ımız için de <strong>depolama</strong> hizmetine ihityaç duyarız.Yine bunların yanında belirli bir dönemde evimize yaprtıracağımız tadilat işleri esnasında da eşyalarımızı kısa süreliğne depolamak için bir yer arayabilirz.Bunun gibi pek çok durumda <strong>Eşya Depolama</strong> hizmeti alma ihtiyacı ortaya çıkabilir.
                </p>
                   </div>
            </div>
        </div>
    </section>  
 </div>
    
    

  )
}

export default Pricing
