import React from 'react'
import FooterOne from '../Components/Footer/FooterOne';
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/esya_depolama.jpg';
import PageBanner from '../Components/PageBanner';
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import Pricing from "../Components/Price/Pricing";
import TestimonialOne from '../Components/Testimonial/TestimonialOne';
import FeaturesOne from '../Components/Features/FeaturesOne';

const PricingPage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='EŞYA DEPOLAMA' bannerBg={bannerBg} currentPage='EŞYA DEPOLAMA' />
        <Pricing/>
        {/* <TestimonialOne/> */}
        {/* <BrandCarouselThree/> */}
       
        <FeaturesOne></FeaturesOne>
        <FooterOne/>
    </>
  )
}

export default PricingPage