import React from 'react'
import FooterOne from '../Components/Footer/FooterOne'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/hakkimizda.jpg';
import PageBanner from '../Components/PageBanner';
import AboutOne from '../Components/About/AboutOne';
import AboutBanner from '../Components/About/AboutBanner';
import Timeline from '../Components/Timeline';
import TeamThree from '../Components/Team/TeamThree';
import BrandCarouselThree from '../Components/Brands/BrandCarouselThree';
import Skill from '../Components/Skills/Skill';


const AboutPage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='hakkimizda' bannerBg={bannerBg} currentPage='HAKKIMIZDA' />
        {/* <AboutOne pt /> */}
        <AboutBanner/>
        <Timeline/>
        <Skill/>
        {/* <TeamThree/> */}
        {/* <BrandCarouselThree/> */}
        <FooterOne/>
    </>
  )
}

export default AboutPage